<template>
  <div>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t('cip.dc.FullInter.title.AddFullInterfaceAcquisition')"
                 @head-save="headSave('save')"
                 @head-save-back="headSave('back')"
                 @head-cancel="headCancel"
    ></head-layout>


    <form-layout ref="formLayout" :column="column" :dataForm.sync="form"></form-layout>

    <!-- <div class="newZtFormBox" style="background-color: white;margin-top: 10px;">
      <el-form  :model="form" label-width="120px" :rules="rules" ref="ruleForm">
        <el-row>
          <el-col :span="8">
            <el-form-item  :label="$t('cip.dc.FullInter.field.InterfaceName')" prop="confName">
              <el-input :placeholder="$t('cip.dc.FullInter.field.InterfaceName')" class='new-form-input' v-model="form.confName"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.FullInter.field.RequestMethod')" prop="requestMode" :placeholder="$t('cip.dc.FullInter.field.RequestMethod')">
              <el-select  class='new-form-input' v-model="form.requestMode"  :placeholder="$t('FullInterfaceAcquisition.msg.msg2')">
                <el-option label="get" value="get"></el-option>
                <el-option label="post" value="post"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">

            <el-form-item :label="$t('cip.dc.FullInter.field.InterfaceAddress')" prop="confApi">
              <el-input  :placeholder="$t('cip.dc.FullInter.field.InterfaceAddress')" class='new-form-input' v-model="form.confApi"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>









        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('FullInterfaceAcquisition.msg.msg1')" prop="requestParameters">
              <el-input :placeholder="$t('FullInterfaceAcquisition.msg.msg1')" class='new-form-input' v-model="form.requestParameters"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.FullInter.field.PrimaryKeyName')" prop="sourcePrimaryKeyName">
              <el-input :placeholder="$t('cip.dc.FullInter.field.PrimaryKeyName')" class='new-form-input' v-model="form.sourcePrimaryKeyName"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.FullInter.field.InterfaceCode')" prop="interfaceCode">
              <el-input  :placeholder="$t('cip.dc.FullInter.field.InterfaceCode')" class='new-form-input' v-model="form.interfaceCode"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.FullInter.field.ResultConfiguration')" prop="confDescribe">
              <el-input  :placeholder="$t('cip.dc.FullInter.field.ResultConfiguration')" class='new-form-input' v-model="form.confdescribe"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.FullInter.field.SystemSourceID')" prop="sourceId">

              <el-select  class='new-form-input' v-model="form.sourceId"  :placeholder="$t('cip.dc.FullInter.field.SystemSourceID')">
                <el-option v-for="(item,index) in idList " :key="index" :label="item.name" :value="item.id"></el-option>

              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.FullInter.field.TopicConfiguration')" prop="confTopic">
              <el-input  :placeholder="$t('cip.dc.FullInter.field.TopicConfiguration')" class='new-form-input' v-model="form.confTopic"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">


            <el-form-item :label="$t('cip.dc.FullInter.field.ForeignKeyCode')" prop="infCode">
              <el-input :placeholder="$t('cip.dc.FullInter.field.ForeignKeyCode')" class='new-form-input' v-model="form.infCode"  autocomplete="off"></el-input>
            </el-form-item>

          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.FullInter.field.ReturnStatusCode')" prop="resultCode">
              <el-input  :placeholder="$t('cip.dc.FullInter.field.ReturnStatusCode')" class='new-form-input' v-model="form.resultCode"  autocomplete="off"></el-input>
            </el-form-item>

          </el-col>
          <el-col :span="8">
            <el-form-item :label="$t('cip.dc.FullInter.field.ReturnStatusValue')" prop="successIdentifier">
              <el-input  :placeholder="$t('cip.dc.FullInter.field.ReturnStatusValue')" class='new-form-input' v-model="form.successIdentifier"  autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item :label="$t('cip.dc.FullInter.field.InterfaceDescription')" prop="operatorName">
              <el-input  :placeholder="$t('cip.dc.FullInter.field.InterfaceDescription')" type = 'textarea' v-model="form.operatorName"  autocomplete="off" ></el-input>
            </el-form-item>
          </el-col>
        </el-row>










      </el-form>
    </div> -->



  </div>
</template>

<script>
import {getqlcode, totalCollectDetail, totalCollectUp} from "@/api/dataAcquisition";
import {listAll2} from "@/api/dataAcquisition/DataServices";
import FormLayout from "@/views/components/layout/form-layout";

export default {
  name: "dataCoonfigAdd",
  data() {
    return {
      idList: [],
      headBtnOptions: [{
        label: this.$t('cip.cmn.btn.saveBtn'),
        emit: "head-save",
        type: "button",
        icon: "",
        btnOptType: 'save',
      },
        {
          label: this.$t('cip.cmn.btn.saveBackBtn'),
          emit: "head-save-back",
          type: "button",
          icon: "",

        }, {
          label: this.$t('cip.cmn.btn.celBtn'),
          emit: "head-cancel",
          type: "button",
          icon: ""
        }],
      rules: {
        confName: [
          {required: true, message: this.$t("cip.dc.FullInter.field.InterfaceName"), trigger: 'change'}
        ],
        requestMode: [
          {required: true, message: this.$t("cip.dc.FullInter.field.RequestMethod"), trigger: 'change'}
        ],
        confApi: [
          {required: true, message: this.$t("cip.dc.FullInter.field.InterfaceAddress"), trigger: 'change'}
        ],
        requestParameters: [
          {required: true, message: this.$t("cip.dc.FullInter.msg.msg1"), trigger: 'change'}
        ],
        sourcePrimaryKeyName: [
          {required: true, message: this.$t("cip.dc.FullInter.field.PrimaryKeyName"), trigger: 'change'}
        ],
        interfaceCode: [
          {required: true, message: this.$t("cip.dc.FullInter.field.InterfaceCode"), trigger: 'change'}
        ],
        tokenId: [
          {required: true, message: this.$t("Token.table.Tokensourceid"), trigger: 'change'}
        ],
        confTopic: [
          {required: true, message: this.$t("cip.dc.FullInter.field.TopicConfiguration"), trigger: 'change'}
        ],
        resultCode: [
          {required: true, message: this.$t("cip.dc.FullInter.field.ReturnStatusCode"), trigger: 'change'}
        ],
        successIdentifier: [
          {required: true, message: this.$t("cip.dc.FullInter.field.ReturnStatusValue"), trigger: 'change'}
        ],
        operatorName: [
          {required: true, message: this.$t("cip.dc.FullInter.field.InterfaceDescription"), trigger: 'change'}
        ],
        confdescribe: [
          {required: true, message: this.$t("cip.dc.FullInter.field.ResultConfiguration"), trigger: 'change'}
        ],
      },
      form: {
        confName: '',
        requestMode: '',
        confApi: '',
        requestParameters: '',
        sourcePrimaryKeyName: '',
        interfaceCode: '',
        // sourceId:'',
        tokenId: '',
        confTopic: '',
        confKey: '',
        resultCode: '',
        successIdentifier: '',
        operatorName: '',
        confdescribe: '',
        infCode: "",
      },
      modeList: [
        {
          label: "get",
          value: "get"
        },
        {
          label: "post",
          value: "post"
        }
      ],
      formLabelWidth: '120px',
      column: [
        {
          label: this.$t("cip.dc.FullInter.field.InterfaceName"),
          prop: "confName",
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.FullInter.field.InterfaceName"),
            trigger: "submit"
          }]
        },

        {
          label: this.$t("cip.dc.FullInter.field.RequestMethod"),
          prop: "requestMode",
          // dicData: [
          //   {
          //     label: "get",
          //     value: "get"
          //   },
          //   {
          //     label: "post",
          //     value: "post"
          //   }
          // ],
          dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=get_post",
          type: "select",
          slot: true,
          props: {
            label: "label",
            value: "value"
          },
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.FullInter.field.RequestMethod"),
            trigger: "click"
          }]
        },

        {
          label: this.$t("cip.dc.FullInter.field.InterfaceAddress"),
          prop: "confApi",
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.FullInter.field.InterfaceAddress"),
            trigger: "submit"
          }]
        },


        {
          label: this.$t("cip.dc.FullInter.msg.msg1"),
          prop: "requestParameters",
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.FullInter.msg.msg1"),
            trigger: "submit"
          }]
        },


        {
          label: this.$t("cip.dc.FullInter.field.PrimaryKeyName"),
          prop: "sourcePrimaryKeyName",
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.FullInter.field.PrimaryKeyName"),
            trigger: "submit"
          }]
        },

        {
          label: this.$t("cip.dc.FullInter.field.InterfaceCode"),
          prop: "interfaceCode",
          disabled: true,
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.FullInter.field.InterfaceCode"),
            trigger: "submit"
          }]
        },


        {
          label: this.$t("cip.dc.FullInter.field.ResultConfiguration"),
          prop: "confDescribe",
          // rules: [{
          //   required: true,
          //   message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.FullInter.field.ResultConfiguration"),
          //   trigger: "submit"
          // }]
        },

        {
          label: this.$t("cip.dc.Token.field.Tokensourceid"),
          prop: "tokenId",
          dicData: this.idList,
          type: "select",
          slot: true,
          props: {
            label: "infCode",
            value: "id"
          },
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.Token.field.Tokensourceid"),
            trigger: "click"
          }]
        },


        {
          label: this.$t("cip.dc.FullInter.field.TopicConfiguration"),
          prop: "confTopic",
          disabled: true,
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.FullInter.field.TopicConfiguration"),
            trigger: "submit"
          }]
        },


        {
          label: this.$t("cip.dc.FullInter.field.ForeignKeyCode"),
          prop: "infCode",
          // disabled:true,
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.FullInter.field.ForeignKeyCode"),
            trigger: "submit"
          }]
        },


        {
          label: this.$t("cip.dc.FullInter.field.ReturnStatusCode"),
          prop: "resultCode",
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.FullInter.field.ReturnStatusCode"),
            trigger: "submit"
          }]
        },


        {
          label: this.$t("cip.dc.FullInter.field.ReturnStatusValue"),
          prop: "successIdentifier",
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.FullInter.field.ReturnStatusValue"),
            trigger: "submit"
          }]
        },


        {
          label: this.$t("cip.dc.FullInter.field.InterfaceDescription"),
          prop: "operatorName",
          span: 16,
          type: "textarea",
          rules: [{
            required: true,
            message: this.$t("cip.cmn.rule.inputWarning") + this.$t("cip.dc.FullInter.field.InterfaceDescription"),
            trigger: "submit"
          }]
        },


      ]
    }
  },
  components: {FormLayout},
  created() {
    this.getTotalCollectDetail()
    this.getlistAll()
    if (this.$route.query.type == 'add') {
      this.form.confTopic = 'ods-it'
      getqlcode().then(res => {
        this.form.interfaceCode = res.data.data
        // this.form.infCode= res.data.data
      })
    }

  },
  methods: {

    getlistAll() {
      listAll2({}).then(res => {

        this.idList = res.data.data

        this.column.forEach(element => {
          if (element.prop == 'tokenId') {
            element.dicData = res.data.data
          }
        });

      })
    },

    getTotalCollectDetail() {
      if (this.$route.query.type !== 'edit') return
      totalCollectDetail(this.$route.query.id).then(res => {
        this.form = res.data.data
        this.form.tokenId = this.form.tokenId.toString()
      })
    }
    ,
    headSave(type) {
      this.$refs.formLayout.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.id) {
            totalCollectUp(this.form).then(() => {
              this.$message.success(this.$t('cip.cmn.msg.success.operateSuccess'));
              if (type == 'back') {
                this.headCancel()
              }
            })
          } else {
            totalCollectUp(this.form).then(() => {
              this.$message.success(this.$t('cip.cmn.msg.success.addSuccess'));
              if (type == 'back') {
                this.headCancel()
              }
            })
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    headCancel() {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },

  }
}
</script>

<style scoped lang="scss">
/* /deep/.el-input__inner{
 width: 200px;
} */
::v-deep .el-form-item__label {
  width: 120px !important;
}
::v-deep .el-form-item__content {
  margin-left: 120px !important;
}
</style>
